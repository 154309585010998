import * as React from "react"
import Layout from "../components/Layout"
import styled from 'styled-components'
import Seo from '../components/Seo'
import Lista_startowa_roztocze from "../assets/Lista_startowaURR24.pdf"
import ZawodyInfo from "../components/ZawodyInfo"

const Title = styled.h1`
  text-align: center;
  margin: auto;
  padding-top: 30px;
  justify-content: center;
`

const ListaLink = styled.a`
  color: black;
  text-align: center;

  :hover{
    color: #34D277;
  }
`

const ListaStartowa = () => {
  return (
    <Layout>
        <Seo title="Lista Startowa "/>
        <Title>
          <ListaLink href={Lista_startowa_roztocze} title="Lista Startowa Ultra Race Roztocze">
                  <h3>Lista Startowa ULTRA RACE ROZTOCZE 2024</h3>
          </ListaLink>
        </Title>

        <ZawodyInfo/>
    </Layout>
  )
}

export default ListaStartowa
